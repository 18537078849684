.PromoReleaseCard__releaseCard--1b7oT {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  outline: none;
  transition: box-shadow 0.3s;
  padding-top: 81.58%;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 767px) {
  .PromoReleaseCard__releaseCard--1b7oT {
    padding-top: 100%;
  }
}
.PromoReleaseCard__releaseCard--1b7oT.PromoReleaseCard__wide--TxjRf {
  padding-top: 57.32%;
}
@media only screen and (max-width: 767px) {
  .PromoReleaseCard__releaseCard--1b7oT.PromoReleaseCard__wide--TxjRf {
    padding-top: 100%;
  }
}
@media (min-width: 1600px) {
  .PromoReleaseCard__releaseCard--1b7oT.PromoReleaseCard__wide--TxjRf {
    padding-top: 57.6%;
  }
}
@media (min-width: 1920px) {
  .PromoReleaseCard__releaseCard--1b7oT.PromoReleaseCard__wide--TxjRf {
    padding-top: 57.6%;
  }
}
@media only screen and (max-width: 767px) {
  .PromoReleaseCard__releaseCard--1b7oT {
    margin-bottom: 20px;
  }
}
.PromoReleaseCard__releaseCard--1b7oT:hover,
.PromoReleaseCard__releaseCard--1b7oT:active {
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.4);
}
.PromoReleaseCard__releaseCard--1b7oT:hover .PromoReleaseCard__coverGradientHover--2-m_C,
.PromoReleaseCard__releaseCard--1b7oT:active .PromoReleaseCard__coverGradientHover--2-m_C {
  opacity: 1;
}
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__cover--1PKIx,
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__info--18xWc,
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__coverGradient--3Fukw,
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__coverGradientHover--2-m_C {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__coverGradient--3Fukw {
  background-image: linear-gradient(315deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
  z-index: 1;
}
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__coverGradientHover--2-m_C {
  will-change: transform;
  transform: translateZ(0);
  transition: opacity 0.5s;
  opacity: 0;
  z-index: 2;
  background-image: linear-gradient(303deg, rgba(252, 73, 26, 0.1), #f7b733);
}
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__info--18xWc {
  z-index: 3;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  border-radius: 5px;
  cursor: pointer;
}
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__releaseName--17-li {
  font-size: 36px;
  font-weight: bold;
  line-height: 1.17;
  text-align: left;
  color: #ffffff;
  margin: 28px 16px 0;
  text-decoration: none;
}
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__releaseName--17-li:focus,
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__releaseName--17-li:active {
  text-decoration: none;
}
@media only screen and (min-width: 578px) and (max-width: 992px) {
  .PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__releaseName--17-li {
    font-size: 22px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__releaseName--17-li {
    font-size: 22px;
  }
}
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__artistName--3SKfj {
  font-size: 24px;
  line-height: 1.5;
  text-align: left;
  color: #eeeeee;
  margin: 6px 16px 0;
}
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__artistName--3SKfj:focus,
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__artistName--3SKfj:active {
  text-decoration: none;
}
@media only screen and (min-width: 578px) and (max-width: 992px) {
  .PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__artistName--3SKfj {
    font-size: 18px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__artistName--3SKfj {
    font-size: 18px;
  }
}
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__bottomInfo--1HpPe {
  margin-top: auto;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  background-color: rgba(42, 42, 42, 0.6);
}
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__releaseInfo--n7zMH {
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 0;
}
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__releaseInfo--n7zMH li {
  color: #eeeeee;
  font-size: 14px;
  white-space: nowrap;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__releaseInfo--n7zMH li {
    font-size: 14px;
  }
}
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__releaseInfo--n7zMH li.PromoReleaseCard__sep--15DS3 {
  width: 4px;
  height: 4px;
  background-color: #f26118;
  border-radius: 4px;
  flex: 0 1 auto;
  display: block;
  margin: 0 10px;
}
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__price--1PUSU {
  border-radius: 2px;
  background-color: #30b8e9;
  color: #ffffff;
  padding: 4px 10px;
  font-size: 16px;
  display: block;
  text-align: center;
  font-weight: 300;
}
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__price--1PUSU strong {
  font-weight: 700;
}
.PromoReleaseCard__releaseCard--1b7oT .PromoReleaseCard__cover--1PKIx {
  height: 100%;
  width: auto;
  left: auto;
}
