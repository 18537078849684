.Banner__container--1Vfn2 {
  text-align: center;
  margin-top: 10px;
}
.Banner__container--1Vfn2.Banner__xs--9kxeq,
.Banner__container--1Vfn2.Banner__sm--3o0-v {
  margin-top: 0;
}
.Banner__container--1Vfn2 .Banner__img--1natg {
  width: 100%;
}
