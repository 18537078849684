.PromoReleases__promoContainer--2lji2 {
  margin-bottom: 20px;
  margin: 0 -4px;
}
@media (min-width: 576px) {
  .PromoReleases__promoContainer--2lji2 {
    margin: 0 -8px;
  }
}
@media (min-width: 768px) {
  .PromoReleases__promoContainer--2lji2 {
    margin: 0 -10px;
  }
}
.PromoReleases__promoContainer--2lji2 > div[class*="ant-col"] {
  padding: 0 4px;
}
@media (min-width: 576px) {
  .PromoReleases__promoContainer--2lji2 > div[class*="ant-col"] {
    padding: 0 8px;
  }
}
@media (min-width: 768px) {
  .PromoReleases__promoContainer--2lji2 > div[class*="ant-col"] {
    padding: 0 10px;
  }
}
